<template>
  <!-- filter:drop-shadow(1px 1px 1px rgba(0,0,0,0.25)); -->
  <div
    v-if="permission.read_perm === 1"
    class="userViewDashboard"
    style="width: 100%;"
  >
    <div style="margin:auto;margin-bottom: 10px; width: 100%;">
      <div>
        <div class="user-dashboard-toolbar">
          <td>
            <v-select
              v-model="paramAPI.doc_type"
              style="margin-left:auto;width:150px; background:white; height:40px; "
              :items="[
                { id: 1, name: 'Hard Copy' },
                { id: 2, name: 'Soft Copy' }
              ]"
              label="Tipe"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            >
              <!-- { id: '', name: 'Tipe' }, -->
              <!-- :disabled="
                getUserProfile.level.find(({ id }) => id === '28') !== undefined
              " -->
            </v-select>
          </td>
          <td>
            <v-select
              v-model="paramAPI.company_id"
              :items="company"
              style="margin-left:auto;width:150px; background:white; height:40px; margin-left:10px;"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="paramAPI.dept_id"
              :items="department"
              style="margin-left:auto;width:150px; background:white; height:40px; margin-left:10px;"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            ></v-select>
          </td>
          <td>
            <v-select
              v-model="paramAPI.status"
              style="margin-left:auto;width:150px; background:white; height:40px; margin-left:10px;"
              :items="[
                { id: '', name: 'Status' },
                { id: 1, name: 'Available' },
                { id: 2, name: 'Loaned' },
                { id: 3, name: 'Gone' },
                { id: 4, name: 'Broken' },
                { id: 5, name: 'Expired' },
                { id: 6, name: 'In process renewal' }
              ]"
              item-text="name"
              item-value="id"
              return-id
              dense
              outlined
            >
              <!-- :disabled="
                getUserProfile.level.find(({ id }) => id === '28') !== undefined
              " -->
            </v-select>
          </td>
          <td>
            <v-text-field
              v-model="paramAPI.keyword"
              style="width:300px; background:white; height:40px; margin-left:10px; "
              label="Search here"
              type="search"
              outlined
              append-icon="mdi-magnify"
              dense
              @keyup.enter="searchEnter"
            >
            </v-text-field>
          </td>
          <td class="d-flex justify-center align-center">
            <v-btn
              style="height:30px; margin-left:10px;font-weight:bold;"
              rounded
              x-small
              color="white"
              class="white--black"
              @click="searchEnter"
            >
              Search
            </v-btn>
          </td>
        </div>

        <div class="user-dashboard-toolbar-2">
          <v-expansion-panels flat accordion>
            <v-expansion-panel v-for="(item, i) in 1" :key="i" flat>
              <v-expansion-panel-header
                class="d-flex align-center justify-center"
                style="padding:0; margin:0; background: #00bcd4;"
              >
                <td>
                  <v-text-field
                    v-model="paramAPI.keyword"
                    style="width:300px; background:white; height:40px; margin-left:10px; "
                    label="Search here"
                    type="search"
                    outlined
                    append-icon="mdi-magnify"
                    dense
                    @keyup.enter="searchEnter"
                  >
                  </v-text-field>
                </td>
                <td class="d-flex justify-center align-center">
                  <v-btn
                    style="height:30px; margin-left:10px;"
                    elevation="2"
                    rounded
                    x-small
                    color="transparent"
                    class="white--text"
                    @click.stop="searchEnter"
                  >
                    Search
                  </v-btn>
                </td>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                id="expansionPanelContentUserDashboard"
                style="background: #00bcd4;"
              >
                <div style="margin-top:10px;">
                  <td>
                    <v-select
                      v-model="paramAPI.doc_type"
                      style="margin-left:auto;width:150px; background:white; height:40px; "
                      :items="[
                        { id: 1, name: 'Hard Copy' },
                        { id: 2, name: 'Soft Copy' }
                      ]"
                      label="Tipe"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    >
                      <!-- { id: '', name: 'Tipe' }, -->
                      <!-- :disabled="
                        getUserProfile.level.find(({ id }) => id === '28') !==
                          undefined
                      " -->
                    </v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="paramAPI.company_id"
                      :items="company"
                      style="margin-left:auto;width:150px; background:white; height:40px; margin-left:10px;"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </td>
                </div>
                <div style="margin-top:10px;">
                  <td>
                    <v-select
                      v-model="paramAPI.dept_id"
                      :items="department"
                      style="margin-left:auto; width:150px; background:white; height:40px; "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="paramAPI.status"
                      style="margin-left:auto; width:150px; background:white; height:40px; margin-left:10px;"
                      :items="[
                        { id: '', name: 'Status' },
                        { id: 1, name: 'Available' },
                        { id: 2, name: 'Loaned' },
                        { id: 3, name: 'Gone' },
                        { id: 4, name: 'Broken' },
                        { id: 5, name: 'Expired' },
                        { id: 6, name: 'In process renewal' }
                      ]"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                    >
                      <!-- :disabled="
                        getUserProfile.level.find(({ id }) => id === '28') !==
                          undefined
                      " -->
                    </v-select>
                  </td>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div style="height:77vh; border-radius:3px; position:relative;">
          <div style="height:96%">
            <v-row
              style="height:100%;  overflow:auto; padding: 10px;"
              no-gutters
            >
              <v-col
                v-for="(item, index) in result"
                :key="index"
                cols="12"
                md="4"
                style="padding:5px;"
              >
                <v-card
                  :color="
                    item.doc_status.id === 2 ? 'rgba(21,208,155,0.5)' : 'white'
                  "
                  hover
                  style=" padding:3px; cursor:default; cursor:pointer;"
                  @click="viewDetail(item)"
                  :disabled="permission.read_perm === 0"
                >
                  <div>
                    <p
                      style="margin:0; font-size:12px; display:flex; justify-content:space-between;"
                    >
                      <span>#{{ item.archive_doc_no }}</span>
                      <span
                        style="text-transform:uppercase; font-weight:bold;"
                        >{{
                          item.document_type.id === 3
                            ? 'SOP'
                            : item.document_type.name
                        }}</span
                      >
                    </p>
                    <p
                      style="width:100%;margin:0; max-height:45px; font-weight:900; font-size:14px; overflow:auto; margin-top:10px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    >
                      {{ item.name }}
                    </p>
                    <p
                      style="margin:0; font-size:14px; border-bottom:1px solid black;"
                    >
                      {{ item.doc_no }}
                    </p>
                  </div>
                  <div
                    style="margin-top:5px;display:flex; justify-content:space-between; flex-wrap:wrap;"
                  >
                    <p
                      style="border-bottom:1px solid rgba(0,0,0,0.3); margin:0; padding:5px; width:50%; font-size:14px;"
                    >
                      <span>
                        Kepemilikan :
                        <span style="font-weight:600;">
                          {{ item.plant !== null ? singkat(item.plant) : '' }}
                        </span>
                      </span>
                    </p>
                    <p
                      style="border-bottom:1px solid rgba(0,0,0,0.3); padding:5px;  margin:0; width:50%; font-size:14px;"
                    >
                      <span>
                        PIC :
                        <span style="font-weight:600;">
                          {{
                            item.pic_department_name === 'HRD'
                              ? 'HCGS'
                              : item.pic_department_name
                          }}
                        </span>
                      </span>
                    </p>
                    <p
                      style="border-bottom:1px solid rgba(0,0,0,0.3); padding:5px;  margin:0; font-size:14px;width:50%;"
                    >
                      <span>
                        Terbit :
                        <span style="font-weight:600;">
                          {{ item.doc_date }}
                        </span>
                      </span>
                    </p>
                    <p
                      style="border-bottom:1px solid rgba(0,0,0,0.3); padding:5px;  margin:0; width:50%; font-size:14px;"
                    >
                      <span>
                        Expired :
                        <span style="font-weight:600;">
                          {{ item.doc_expiry_date }}
                        </span>
                      </span>
                    </p>
                    <p
                      style="margin:0; width:50% width:100%; font-size:14px; padding:5px;"
                    >
                      <span> Status : </span>
                      <span style="font-weight:600;">
                        {{ item.doc_status.name }}
                      </span>
                    </p>
                  </div>
                </v-card>
              </v-col>
              <!-- <div
              v-if="isLoading"
              class="d-flex justify-center align-center"
              style="position:absolute; top:0; left:0; right:0; bottom:0; background:white;"
            > -->
              <v-toolbar-title
                v-if="result.length < 1"
                style="margin:auto; font-size:18px; text-transform:uppercase; font-weight:bold; color:rgba(0,0,0,0.5);"
              >
                Data not found
              </v-toolbar-title>
              <!-- </div> -->
            </v-row>
            <div
              v-if="isLoading"
              class="d-flex justify-center align-center"
              style="position:absolute; top:0; left:0; right:0; bottom:0; background:white;"
            >
              <v-progress-circular
                :size="120"
                :width="10"
                color="cyan"
                indeterminate
                style="fontSize:12px;"
                >Please wait</v-progress-circular
              >
            </div>
          </div>
          <div style="display:flex; align-items:center; height:50px;">
            <v-pagination
              v-model="page"
              :length="Math.ceil(paramAPI.arrLength / paramAPI.limit) || 1"
              :total-visible="5"
              style="height:45px;position:relative; z-index:2;"
            ></v-pagination>
          </div>
        </div>

        <DetailDocument :result="detail" :permission="permission" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['permission'],
  components: {
    DetailDocument: () => import('../archive-manage/Detail')
  },
  data: () => ({
    cek: false,
    eSanqua: buildType.apiURL('esanqua'),
    download: buildType.apiURL('download'),
    page: 1,
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 9,
      status: '',
      arrLength: 0,
      doc_type: '',
      company_id: '',
      dept_id: ''
    },
    company: [],
    department: [],
    result: [],
    detail: null,
    isLoading: false
  }),
  watch: {
    page() {
      this.paramAPI.offset = this.page - 1
      this.initDataTable()
    },
    'paramAPI.doc_type'() {
      this.initDataTable()
    },
    'paramAPI.company_id'() {
      this.initDataTable()
    },
    'paramAPI.dept_id'() {
      this.initDataTable()
    },
    'paramAPI.status'() {
      this.initDataTable()
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  created() {
    // if (this.getUserProfile.level.find(({ id }) => id === '28') !== undefined) {
    this.paramAPI.doc_type = 1
    // }
    console.log(this.getUserProfile.level)
    this.initDataTable()
    this.initDropdown()
  },
  methods: {
    ...mapActions(['dropdownCompany', 'dropdownDepartment']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    initDropdown() {
      this.companyDropdown()
      this.departmentDropdown()
    },
    companyDropdown() {
      this.company = [{ id: '', name: 'company' }]
      this.dropdownCompany()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            // if (this.getUserProfile.sanqua_company_id === res.data[i].id) {
            this.company.push(res.data[i])
            // }
          }
          // this.company = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    departmentDropdown() {
      this.department = [{ id: '', name: 'department' }]
      this.dropdownDepartment()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.department.push(res.data[i])
          }
          // this.department = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    async initDataTable() {
      this.isLoading = true
      await axios
        .get(
          `${this.eSanqua}archive_document/list?limit=${
            this.paramAPI.limit
          }&offset=${this.paramAPI.offset *
            this.paramAPI.limit}&is_archived=&keyword=${
            this.paramAPI.keyword
          }&doc_status_id=${this.paramAPI.status}&document_type_id=${
            this.paramAPI.doc_type
          }&plant_id=${this.paramAPI.company_id}&pic_department_id=${
            this.paramAPI.dept_id
          }`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.result = res.data.data
            this.paramAPI.arrLength = res.data.total_record
          } else {
            this.result = []
            this.paramAPI.arrLength = 0
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.isLoading = false
    },
    viewDetail(pValue) {
      axios
        .get(`${this.eSanqua}archive_document/detail/${pValue.id}`)
        .then(res => {
          this.detail = res.data.data
          setTimeout(() => {
            document.getElementById('archivedetail').click()
          }, 300)
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchEnter() {
      this.initDataTable()
    },
    downloadSoftCopy(pValue) {
      console.log(pValue)
      axios({
        url: `${this.download}esanqua/archive_document/edocument/${pValue.file}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', pValue.file)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    singkat(raw) {
      let name = ''
      switch (raw.id) {
        case 1:
          name = 'TMP'
          break
        case 2:
          name = 'IMP'
          break
        case 3:
          name = 'GIT'
          break
        case 4:
          name = 'KAJ'
          break
        case 5:
          name = 'WIM'
          break
        case 6:
          name = 'SMI'
          break
      }
      return name
    }
  }
}
</script>
<style lang="scss" scoped>
.userViewDashboard {
  position: relative;
  z-index: 2;
  width: 100%;

  .docSpanDownload {
    border: 1px solid rgba(0, 0, 0, 0.568);
    font-weight: 500;
    border-radius: 3px;
    padding: 1px 2px;
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.384);

    &:hover {
      font-weight: bold;
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.384);
    }
  }
}
.user-dashboard-toolbar {
  z-index: 2;
  position: relative;
  // background: #00bcd4;
  padding: 5px;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
}
.user-dashboard-toolbar-2 {
  display: none;
}

#expansionPanelContentUserDashboard > * {
  padding: 0px;
}
@media (max-width: 768px) {
  .user-dashboard-toolbar {
    display: none;
  }
  .user-dashboard-toolbar-2 {
    background: #00bcd4;
    display: flex;
  }
}
</style>
